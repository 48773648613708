@import "../../const/settings.scss";
.wp-knowledgeDentistry {
  max-width: 1200px;
  display: flex;
  margin: 80px auto;
  flex-direction: column;
  align-items: center;
  position: relative;
  .wp-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .moreButtonDesktop {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0 15px;
      // height: 40px;
      border-radius: 8px;
      // border: 1px solid #00c5d7;
      background-color: #fff;
      cursor: pointer;
      // margin-top: 30px;
      p {
        color: #00c5d7;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        margin-bottom: 0;
        margin-right: 4px;
      }
      .more-button-img {
        width: 20px !important;
      }
    }
    h2 {
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 52px */
      text-transform: uppercase;
      color: #2a3467;
      margin-bottom: 8px;
      text-align: start;
    }
  }
  .subTitle {
    max-width: 600px;
    color: var(--Color-4, #363636);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-bottom: 45px;
  }
  .line {
    height: 4px;
    width: 120px;
    border-radius: 3px;
    background-color: #00c5d7;
    margin-bottom: 20px;
  }
  .wp-content {
    .wp-contentItem {
      cursor: pointer;
      .wp-contentImage {
        width: 100%;
        height: 253px;
        margin-bottom: 24px;
        .ant-image {
          width: 100%;
          height: 100%;
        }
        .contentImage {
          object-fit: cover;
          border-radius: 8px;
          position: relative !important;
        }
      }
      .itemBlog {
        color: var(--Color-BrandColor, #00c5d7);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
        margin-bottom: 8px;
      }
      .itemTitle {
        color: var(--Color-Primary, #2a3467);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 31.2px */
        margin-bottom: 8px;
      }
      .itemContent {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        overflow: hidden;
        color: var(--Color-Text, #6884a7);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
  .moreButton {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #00c5d7;
    background-color: #fff;
    cursor: pointer;
    margin-top: 30px;
    p {
      color: #00c5d7;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
  .see-more-button {
    display: flex;
    width: 120px;
    height: 35px !important;
    padding: 12px 20px !important;
    flex-shrink: 0;
    border-radius: 5px !important;
    background: var(--Color-1, #2a3467) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    border: none !important;
    margin-top: 30px;
    span {
      margin-right: 5px;
      color: var(--Color-3, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .arrow {
    position: absolute;
    top: 42%;
    margin-top: -5px;
    background: #f9f9f9;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  }
  .arrow-left {
    left: -69px;
  }
  .arrow-right {
    right: -69px;
  }
  .icon-prev {
    transform: rotate(180deg);
  }
  .swiper-wrapper {
    max-width: 1200px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    position: unset !important;
    margin-top: 0;
  }
  .swiper-pagination-bullet-active {
    background: #00c5d7 !important;
  }
}

@include media(lg) {
  .wp-knowledgeDentistry {
    margin: 30px 15px;
    .wp-content {
      width: 100%;
    }
  }
  .arrow {
    display: none !important;
  }
}

@include media(md) {
  .wp-knowledgeDentistry {
    .moreButton {
      margin-top: 16px !important;
    }
    .moreButtonDesktop {
      display: none !important;
    }
    h2 {
      font-size: 14px !important;
      text-transform: none !important;
    }
    .subTitle {
      font-size: 12px !important;
      margin-bottom: 16px !important;
    }
    // .line {
    //   margin-bottom: 28px;
    // }
    .wp-content {
      width: 100%;
      // margin-bottom: 28px;
      .wp-contentItem {
        .wp-contentImage {
          height: 110px;
          margin-bottom: 14px;
          .contentImage {
            height: 110px;
            width: 100%;
          }
        }
      }
      .itemBlog {
        font-size: 12px !important;
      }
      .itemTitle {
        font-size: 14px !important;
      }
      .itemContent {
        font-size: 14px !important;
      }
    }
    .moreButton {
      display: flex;
      padding: 0 20px;
      height: 40px;
      p {
        font-size: 14px !important;
      }
    }
  }
}

@include media(sm) {
  .wp-knowledgeDentistry {
    .wp-content {
      width: 100%;
    }
  }
}
